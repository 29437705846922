import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import Navigation from "components/layout/navigation/navigation"
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby"
import Footer from "./footer"
import { Container } from 'components/ui'

import "styles/pages/landing.scss"

const Layout = ({ children }) => {

  const [ optloading, setOptloading ] = useState(true);

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'optimize.activate' })
    setTimeout(() => {setOptloading(false)}, 20);
  }, [])

  const bodyClass = 'text-base md:text-xl md:leading-relaxed';
  return (
    <>
      <Helmet>
        <script>
          window.dataLayer = window.dataLayer || [];
        </script>
        <script src="https://kit.fontawesome.com/5bfe31fa0c.js" crossOrigin="anonymous"></script>
        <body className={ (optloading) ? `${bodyClass} --async-hide--` : bodyClass }/>
      </Helmet>

      <div className="bg-aubergine">
        <Container>
          <Navigation logoColor="white" textColor="white"/>
        </Container>
      </div>
      {children}


      <CookieConsent
        location="bottom"
        buttonText="Close"
        cookieName="cookie_policy"
        style={{ background: "#dfe2e8", color: '20202D', fontSize: '12px' }}
        buttonStyle={{ backgroundColor: "#20202D", color: "#ffffff", fontSize: "13px", borderRadius: '4px', margin: '10px' }}
        contentStyle={{}}
        expires={150}
      >
        By continuing to use this site you consent to the use of cookies in accordance
        with our <Link to="/cookie-policy/" className="">cookie policy</Link>.
      </CookieConsent>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
