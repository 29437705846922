import React from "react"
import Layout from "components/layout/layout-feature"
import SEO from "components/seo"
import { SignupButton, Link, Row, Typography, Container, Section, SectionTitle } from "components/ui"
import { BackupTypes } from "../../components/sections"

const DatabaseBackup = () => (
  <Layout isDark={true} withBackground={true}>
		<SEO refKey="runcloud-backup" />

    <div className="bg-aubergine text-white py-20">
      <Container className="">
        <h1 className="font-heading tracking-tight text-5xl md:text-6xl md:leading-tight font-black text-center">
          RunCloud backups just got better.
        </h1>
        <Typography tag="p" className="mt-6 text-center">
          Hello RunCloud users, we know you've been looking for an alternative backup solution.
        </Typography>

        <Row>
          <div className="m-auto md:flex items-start justify-center">
            <Typography tag="h2" size="h3" className="font-bold">Why using <br/>SimpleBackups with RunCloud?</Typography>
            <ul className="gap-4 text-left">
              <li>
                <i className="fa fa-check text-green mr-2"></i>we don't charge you based on the number of backups and storage
              </li>
              <li><i className="fa fa-check text-green mr-2"></i>we don't limit database backup to MySQL or to the databases created via RunCloud</li>
              <li><i className="fa fa-check text-green mr-2"></i>we allow much more backup options like "Backup all your databases at once" </li>
              <li><i className="fa fa-check text-green mr-2"></i>stream your backups to your storage, no need for additional free space on your server</li>
              <li><i className="fa fa-check text-green mr-2"></i>search for backups taken on certain date/time, no limitation</li>
              <li><i className="fa fa-check text-green mr-2"></i>backup all the files and folders your want, not just the web app folder</li>
              <li><i className="fa fa-check text-green mr-2"></i>store your backups where you want, meaning you're not bound to RunCloud or to SimpleBackups</li>
              <li><i className="fa fa-check text-green mr-2"></i>store backups for any period of time, not limited to 1 single month</li>
            </ul>
          </div>
        </Row>
      </Container>
    </div>

    <Section>
      <Container>
        <Row>
          <SectionTitle
            title={(<>Backup your servers and databases</>)}
            tag="h2"
            align="center"
          />
          <BackupTypes/>
        </Row>
      </Container>
    </Section>


    <Section color="alt" >
      <Container className="text-center">
        <Row>
          <SectionTitle
            title={(<>Some features we know you'll like</>)}
            tag="h2"
            align="center"
          />

          <div className="grid md:grid-cols-4 gap-16 text-left">
            <div>
              <i className="fad fa-terminal text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4">One-line setup</h3>
              <p className="text-base">Add your servers and databases with just a few clicks.</p>
            </div>
            <div>
              <i className="fad fa-robot text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4">Backup scheduling</h3>
              <p className="text-base">Choose how often you want us to back up your files and databases, without compromise.</p>
            </div>
            <div>
              <i className="fad fa-clinic-medical text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4">Quick recovery</h3>
              <p className="text-base">Find and restore the backups you need. Restore them in batches or independently.</p>
            </div>
            <div>
              <i className="fad fa-ball-pile text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4">Incremental backups</h3>
              <p className="text-base">Optimize your storage usage by only backing up the files that have changed.</p>
            </div>
            <div>
              <i className="fad fa-warehouse-alt text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4">Connect any storage</h3>
              <p className="text-base">Connect your preferred storage provider, or host your backups with our native solution, SimpleStorage.</p>
            </div>
            <div>
              <i className="fad fa-code text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4">Workflow integration</h3>
              <p className="text-base">Integrate your SimpleBackups files in your workflow with triggers, integrations, webhooks, or our API.</p>
            </div>
            <div>
              <i className="fa-duotone fa-binary-lock text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4">AES-256 Encryption</h3>
              <p className="text-base">With built-in AES-256 backup encryption, file and database backups can only be decrypted by you.</p>
            </div>
            <div>
              <i className="fad fa-bell-on text-primary text-4xl" />
              <h3 className="text-xl font-bold mt-4">Backup notifications</h3>
              <p className="text-base">Get notified about the state of your backups through email, Slack, Discord, or Pushover.</p>
            </div>
          </div>
          <div className="text-center w-full mt-12">
            <Link arrow="right" href="/features/" className="text-lg">See all features</Link>
          </div>
        </Row>

      </Container>
    </Section>

    <Section color="primary">
      <Container className="container text-center">
        <Row>
          <SectionTitle
            title={(<>Setup backups of your RunCloud managed servers</>)}
            subTitle={(<>Set up and automate your first backup in less than 3 minutes.<br/> No credit card required</>)}
            tag="h2"
            align="center"
          />
          <SignupButton noSubtitle text="Create your first backup" className="mt-6" urlAtrr={{ sb_source: "website", sb_term: "home-cta" }} />
        </Row>
      </Container>
    </Section>
  </Layout>
)

export const frontmatter = {
  pageMetaData: {
    refKey: "runcloud-backup",
    title: "RunCloud backup alternative",
    description: "RunCloud backup alternative. No restriction, less expensive, mores features and integrated with RunCloud.",
    author: "simplebackups.com"
  }
}

export default DatabaseBackup
